<template>
  <div>
    <div class="top">
      <b-container class="selectRD">
        <b-row>
          <b-col xl='3' lg='12' md='12' cols='12' order='1' order-xl='1' order-lg='1' order-md='2'>
            <b-img class="tourLogoS" :src="data.event_sponsor_logo"></b-img>
          </b-col>
          <span class="reportsPad">
            <b-col xl='5' lg='12' md='12' cols='12' order='2' order-xl='2' order-lg='2' order-md='1' class="dropSel">
              <b-row class="reportDropdown">
                  <b-col lg='3' md='12' class='mobC'>
                    <span
                      :class="{ developGreen: develop === config.VUE_APP_ID_ADT }"
                      class="filterReports"
                    >
                      Filter Reports
                    </span>
                  </b-col>
                  <b-col lg='9' md='12'>
                    <div :class="drop">
                      <b-nav card-header pills>
                        <b-dropdown
                          v-if="Array.isArray(tmparams.reports.reports_entry)"
                          :text="title"
                          class="dropdownReport"
                        >
                          <b-dropdown-item
                            v-on:click="changeReport"
                            v-for="(reports, index) in filterExemptions"
                            :key="index"
                            v-show="reports.report_title !== 'Live Scoring Monitor'"
                            :title="reports.report_url"
                            :value="reports.report_title"
                            >{{ reports.report_title }}</b-dropdown-item
                          >
                        </b-dropdown>
                        <b-dropdown
                          class="dropdownReport"
                          v-else
                          :text="title"
                        >
                          <b-dropdown-item
                            v-on:click="changeReport"
                            v-for="(reports, index) in tmparams.reports"
                            :key="index"
                            :title="reports.report_url"
                            :value="reports.report_title"
                            >{{ reports.report_title }}</b-dropdown-item
                          >
                        </b-dropdown>
                      </b-nav>
                    </div>
                  </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-if="tmparams.multi_course === 'Y'"
                  class="col-md-6 multi"
                >
                  <span>Courses:</span>

                  <span
                    class="multiList"
                    v-for="(multi, index) in tmparams.courses.courses_entry"
                    :key="index"
                  >
                    <span :class="multi.course_X_colour">•</span
                    ><span class="multiName">{{ multi.course_X_id }}</span>
                  </span>
                </b-col>
              </b-row>
            </b-col>
          </span>
          <b-col class="mobHide" order='3' v-if="develop === 'apga'">
            <iframe
              class="iframemovement"
              width="260px"
              height="104px" 
              style="border: none!important;"
              src="https://wp-asiantour.ocs-sport.com/wp-content/themes/asian-tour/ball_counter_december_2022/index.html"
            >
            </iframe>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg='4' md='12' cols='12' class="reportTitle">
            <h3 class="tournHead" :class="{ developGreen: develop === 'adt' }">
              <div>
                  {{ data.full_name }} - {{title}}
              </div>
            </h3>
            <h3
              class="tournHead dates"
              :class="{ developGreen: develop === 'adt' }"
            >
              {{ data.course_dates }}
            </h3>
          </b-col>
          <b-col> </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Top Section Reports",
  props: ["develop", "config", "showGender", "Stableford", "season", "code" ],
  components: {
  },
  data() {
    return {
      data: [],
      previousData: [],
      tmparams: [],
      // showGender: {},
      drop: "drop",
      currentReport: this.$route.query.url,
      home: false,
      tabs: true,
      title: this.$route.query.title,
    };
  },
  methods: {
    changeReport: function(event) {
      var report = event.target.getAttribute("title");
      var title = event.target.getAttribute("value");
      this.title = title;
      this.report = report;
      this.$emit("onEmitEvent", title);
      this.$emit("onEmitEventReport", report);
      return (
        (this.currentReport = report),
        axios
          .get(this.reportTitle)
          .then((response) => (
            this.data = response.data,
            this.$emit("onEmitEventReportData", this.data)
          ))
      );
    },
  },
  computed: {
    filterExemptions: function() {
      return this.tmparams.reports.reports_entry.filter(
        (reports_entry) =>
          !reports_entry.report_title.indexOf("Tournament Entries") ||
          !reports_entry.report_title.indexOf("Round 1 Draw") ||
          !reports_entry.report_title.indexOf("Round 2 Draw") ||
          !reports_entry.report_title.indexOf("Round 3 Draw") ||
          !reports_entry.report_title.indexOf("Round 4 Draw") ||
          !reports_entry.report_title.indexOf("Round 5 Draw") ||
          !reports_entry.report_title.indexOf("Round 6 Draw") ||
          !reports_entry.report_title.indexOf("Round 1 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 2 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 3 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 4 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 5 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 6 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 2 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 3 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 4 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 5 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 6 Scoreboard") ||
          !reports_entry.report_title.indexOf("Final Result") ||
          !reports_entry.report_title.indexOf("Live Scoring Monitor")
      );
    },
    reportTitle: function(url) {
      if (this.currentReport.includes("qs.ocs-asia.com")) {
        if (this.currentReport.indexOf("tmentry") !== -1) {
          url =
            process.env.VUE_APP_TIC_BASE +
            'qsc' +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.code +
            "-entry-entries.json"
        } else {
          if (this.currentReport.indexOf("tmscore") !== -1) {
            var subRep3 = this.currentReport.lastIndexOf("params=") + 7;
            var indNext3 = this.currentReport.indexOf("~", subRep3);
            var repParams1 = this.currentReport.substr(
              subRep3,
              indNext3 - subRep3
            );

            if (repParams1 && repParams1.indexOf(".cgi") < 0) {
              url =
                process.env.VUE_APP_API +
                process.env.VUE_APP_ID_ASIAN_TOUR +
                "/cache/" +
                'qsc' +
                "/" +
                this.season +
                "/" +
                this.season +
                "-" +
                this.code +
                "-scores-" +
                repParams1 +
                ".json" +
                "?randomadd=" +
                new Date().getTime();
            } else {
              url =
                process.env.VUE_APP_API +
                process.env.VUE_APP_ID_ASIAN_TOUR +
                "/cache/" +
                'qsc' +
                "/" +
                this.season +
                "/" +
                this.season +
                "-" +
                this.code +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
            {
              url =
                process.env.VUE_APP_API +
                process.env.VUE_APP_ID_ASIAN_TOUR +
                "/cache/" +
                'qsc' +
                "/" +
                this.season +
                "/" +
                this.season +
                "-" +
                this.code +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.currentReport.indexOf("tmdraw") !== -1) {
            var subRep5 = this.currentReport.lastIndexOf("round=") + 6;
            var indNext5 = this.currentReport.indexOf("~", subRep5);
            var drawRnd5 = this.currentReport.substr(subRep5, indNext5 - subRep5);
            url =
              process.env.VUE_APP_API +
              process.env.VUE_APP_ID_ASIAN_TOUR +
              "/cache/" +
              'qsc' +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.code +
              "-draw-R" +
              drawRnd5 +
              "-draw.json"
          } else if (this.currentReport.indexOf("tmresult") !== -1) {
            var subRep4 = this.currentReport.lastIndexOf("result=") + 7;
            var indNext4 = this.currentReport.indexOf("~", subRep4);
            var resNum4 = this.currentReport.substr(subRep4, indNext4 - subRep4);
            // console.log("resNum === ");
            // console.log(resNum1);
            url =
              process.env.VUE_APP_API +
              process.env.VUE_APP_ID_ASIAN_TOUR +
              "/cache/" +
              'qsc' +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.code +
              "-result-result-" +
              resNum4 +
              ".json"
          } else {
            url =
              process.env.VUE_APP_API +
              process.env.VUE_APP_ID_ASIAN_TOUR +
              "/cache/" +
              'qsc' +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.code +
              "-result-result-PF.json" 
          }
        }
        return url;
      } else {
        if (this.currentReport.indexOf("tmentry") !== -1) {
          url =
            process.env.VUE_APP_TIC_BASE +
            this.develop +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.code +
            "-entry-entries.json"
        } else {
          if (this.currentReport.indexOf("tmscore") !== -1) {
            var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
            var indNext2 = this.currentReport.indexOf("~", subRep2);
            var repParams = this.currentReport.substr(
              subRep2,
              indNext2 - subRep2
            );

            if (repParams && repParams.indexOf(".cgi") < 0) {
              url =
                process.env.VUE_APP_API +
                process.env.VUE_APP_ID_ASIAN_TOUR +
                "/cache/" +
                this.develop +
                "/" +
                this.season +
                "/" +
                this.season +
                "-" +
                this.code +
                "-scores-" +
                repParams +
                ".json" +
                "?randomadd=" +
                new Date().getTime();
            } else {
              url =
                process.env.VUE_APP_API +
                process.env.VUE_APP_ID_ASIAN_TOUR +
                "/cache/" +
                this.develop +
                "/" +
                this.season +
                "/" +
                this.season +
                "-" +
                this.code +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
            {
              url =
                process.env.VUE_APP_API +
                process.env.VUE_APP_ID_ASIAN_TOUR +
                "/cache/" +
                this.develop +
                "/" +
                this.season +
                "/" +
                this.season +
                "-" +
                this.code +
                "-scores-latest.json" +
                "?gt=y&randomadd=" +
                new Date().getTime();
            }
          } else if (this.currentReport.indexOf("tmdraw") !== -1) {
            var subRep = this.currentReport.lastIndexOf("round=") + 6;
            var indNext = this.currentReport.indexOf("~", subRep);
            var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
            url =
              process.env.VUE_APP_API +
              process.env.VUE_APP_ID_ASIAN_TOUR +
              "/cache/" +
              this.develop +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.code +
              "-draw-R" +
              drawRnd +
              "-draw.json"
          } else if (this.currentReport.indexOf("tmresult") !== -1) {
            var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
            var indNext1 = this.currentReport.indexOf("~", subRep1);
            var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
            // console.log("resNum === ");
            // console.log(resNum1);
            url =
              process.env.VUE_APP_API +
              process.env.VUE_APP_ID_ASIAN_TOUR +
              "/cache/" +
              this.develop +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.code +
              "-result-result-" +
              resNum1 +
              ".json"
          } else {
            url =
              process.env.VUE_APP_API +
              process.env.VUE_APP_ID_ASIAN_TOUR +
              "/cache/" +
              this.develop +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.code +
              "-result-result-PF.json" 
          }
        }
        return url;
      }
    },
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_TIC_BASE + 'apga' + "/tmticx?LEADERBOARD=" + new Date().getTime()
      )
      .then(response => {
        this.data = response.data;
        this.seasonCode = response.data.tm_params.season_code;
        this.TournCode = response.data.code;
        // this.classGroup = response.data.class_group.toLowerCase().trim();
        this.classGroup = response.data.class_group;
        console.log("this.classGroup")
        console.log(this.classGroup)
        if (this.classGroup === 'QSC') {
          return axios.get( process.env.VUE_APP_TIC_BASE + 'qsc' + "/" + this.seasonCode + "/" + this.seasonCode + "-" + this.TournCode + "-" + "tmticx?TOURNINFO=" + new Date().getTime());
        } else {
          return axios.get( process.env.VUE_APP_TIC_BASE + this.develop + "/" + this.seasonCode + "/" + this.seasonCode + "-" + this.TournCode + "-" + "tmticx?TOURNINFO=" + new Date().getTime()); 
        }
      })
      .then(response => {
        this.tmparams = response.data;
        this.reports = response.data.reports.reports_entry;
      })
  },
};
</script>

<style scoped>
.colorMess {
  background: red!important;
  color: white;
  font-weight: 500;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.livemessage {
  background: #133f7b;
  color: white;
  font-weight: 500;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.reports {
  padding-top: 4px;
}
span.reportsPad {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  display: contents;
}
::v-deep .leaderboardTabs > div > ul {
  display: none;
}
.reportTitle {
  margin-top: 1.5em;
}
::v-deep .nav-tabs{
  border-bottom: 0;
  /* background-color: #d4d4d4; */
}
::v-deep .nav-tabs>li>a{
  color: #133f7b !important;
}
.cor-grn {
  color: #3c9933 !important;
  float: right;
  margin-top: 13px;
  font-size: 60px;
}
::v-deep .tab-pane>div>table>thead>tr>th:first-child{
border-top-left-radius: 0;
}
::v-deep .tab-pane>div>table>thead>tr>th:last-child{
border-top-right-radius: 0;
}
.cor-org {
  color: #e07020 !important;
  float: right;
  margin-top: 13px;
  font-size: 60px;
}
.cut-row {
  background: #aa0003 !important;
  color: #fff;
  text-align: center;
}
.iframemovement {
  float: right;
  margin-right: -3px;
}
.bannerlogosReport {
  float: right;
}
::v-deep .dropdownReport > ul {
  width: 300px;
  text-align: center;
}
::v-deep .btn-secondary {
  color: #000;
  background-color: #ffffff;
  border-color: #ebebeb;
  font-size: 11pt;
  width: 250px;
}
.ReportFilter {
  display: flex;
}
.filterReports {
  color: #16498f;
  font-size: 10pt;
  font-weight: bold;
}
.main {
  padding-top: 2%;
  padding-bottom: 2%;
}
.drop {
  padding-left: 10%;
  padding-bottom: 2%;
}
.developGreen {
  color: #76c3bd !important;
}
::v-deep .developGreen > div > ul > li > .nav-link.active {
  background-color: #76c3bd !important;
  border-color: #76c3bd !important;
}
.sponsor {
  margin-top: -10%;
  width: 20%;
  padding-left: 10%;
}
.multi {
  font-size: 13px !important;
  line-height: 50px;
  color: #0b3f7e;
  font-weight: bold;
  margin-top: 15px;
}
.B {
  color: #333333;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
}
.Y {
  color: #d8db22;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
}
.C {
  color: #1E90FF;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
}
.R {
  color: #BB0000;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
}
.G {
  color: #3c9933;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
}
.O {
  color: #e07020;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
}

::v-deep .nav-link.active {
  color: white !important;
  background-color: #133f7b !important;
  width: 100%;
  border-color: #133f7b !important;
  height: 100%;
}
.selectRD {
  padding: 20px 1%;
}
::v-deep .nav-link {
  color: black !important;
  width: 100%;
  font-size: 14px;
}
.dropSel {
  padding: 0.5% 1%;
  background-color: #fff;
  border-radius: 10px;
  height: 100px;
}
::v-deep li.nav-item {
  width: 25%;
  text-align: center;
}
::v-deep .mt-3 {
  margin-top: 0 !important;
}
.tourLogoS {
  max-width: 180px;
  margin: 0;
  height: auto;
  margin-bottom: 56px;
  display: inline-block;
  max-height: 150px;
}
.tournHead {
  text-align: left;
  color: #133f7a;
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 19px!important;
}
.dates {
  font-size: 13px !important;
}
.top {
  background-color: #ebebeb;
}
.mid {
  background-color: #f1f1f1;
}
@media only screen and (max-width: 1200px) {
  span.reportPad {
    width: 510px !important;
    padding-left: 15px;
    padding-right: 15px;
    display: block !important;
  }
  .bannerlogosReport {
    display: none;
  }
  .iframemovement {
    float: right;
    margin-right: -3px;
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  span.reportsPad {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    display: block !important;
  }
  .tourLogoS{
    margin-bottom: 0;
    padding-top: 50px;
  }
  .drop{
    padding-left: 35%;
  }
  .mobC{
    text-align: center;
  }
}
@media only screen and (max-width: 768px) {
  .mobHide {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .ReportFilter {
    display: block;
    text-align: center;
    position: relative;
    top: 8px;
  }
  .sponsor {
    margin-top: 2%;
    width: 35%;
    padding-left: 4%;
  }
  .drop {
    /* margin-top: -10%; */
    margin-top: 0;
    padding: 0;
  }
  .selectRD {
    padding: 2% 4%;
  }
  .reportDropdown {
    margin: auto;
    display: block;
  }
  .dropdownReport {
    display: contents;
  }
  ::v-deep .nav-link {
    font-size: 12pt;
}
}
</style>
